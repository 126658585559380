.Calendar_OTPCRM{
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: left;
  font-size: 16px;
  width: 300px !important;
  border: 2px solid #000;
  font-weight: 500;
}
.Calendar_OTPCRM::placeholder{
  color: gray;
  font-weight: 400;
}
.TimePicker_OTPCRM input{
  width: 290px;
  margin: 0 auto;
  border: 2px solid #000;
  height: 35px;
  color: #000;
  font-size: 14px;
}